
import { defineComponent } from 'vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmButton from '@/components/shared/TmButton.vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'

export default defineComponent({
  name: 'PersonalMyAccount',
  components: {
    TmStatus,
    TmCountry,
    TmButton,
    PageContent,
    DetailsTable,
    DetailsTableItem,
  },
  props: {
    menuTitle: {
      type: String,
    },
  },
  setup() {
    const country = { id: 'ee', name: 'Estonia' }
    const phone = { id: 'ee', name: '+37258440305' }

    return {
      country,
      phone,
    }
  },
})
